import React,{ useEffect, useState } from "react";

import Footer from '../../views/layouts/footer';
import LeftSiderbar from '../../views/layouts/left-sidebar';
import SubHeader from '../../views/my_account/header';
import axios from "axios";
import Header from "../layouts/header";
import Loader from './../layouts/loader'
import { checkAuth, constant } from "../../utils/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Paper from "@material-ui/core/Paper";
// import IconButton from "@material-ui/core/IconButton";
import MapboxAutocomplete from "react-mapbox-autocomplete";

import $ from 'jquery';

// class Dealerinfo extends Component{
const Dealerinfo = props =>{
    const [title, setTitle] = useState("Dealerhop | Dealership Information");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [dealership_name, setDealershipName] = useState("");
    const [dealership_location, setDealerLocation] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [province, setProvince] = useState("");
    const [provinces, setProvinces] = useState([]);

    
    const [err_first_name, setErrFirstName] = useState("");
    const [err_last_name, setErrLastName] = useState("");
    const [err_dealership_name, setErrDealerName] = useState("");
    const [err_dealership_location, setErrDealerLocation] = useState("");
    const [err_province, setErrProvince] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isRedirect, setIsRedirect] = useState(false);
    const [shimmerEffect, setShimmerEffect] = useState('shimmerEffect');

    const mapAccess = {
        mapboxApiAccessToken:
          "pk.eyJ1IjoiZGV2ZWxvcGVyYWJpZGFsaSIsImEiOiJjbDF3MG16NzUxNGg0M2txdnNtcDgzbmR2In0.BIJcWX7_Z30IXudESTAbbw"
      };

      
    function _suggestionSelect(result, lat, long, text) {
        axios({
            method: "GET",
            url: constant.GEOCODING,
            params: {
                latitude: lat,
                longitude: long,
            },
        })
        .then((response) => {
            console.log(response.data)
            setProvince(response.data.province);
        });
        setDealerLocation(result);
        setLatitude(lat);
        setLongitude(long);
    }

    useEffect(() => {
        setTimeout(() => {            
            document.querySelector('body').scrollTo(0,0);
        }, 100);
      }, []);
    useEffect(()=>{
        getProfile();
        getProvinceNew();
        document.title = title;
// setTimeout(() => {
    
//     $(".react-mapbox-ac-input").val(dealership_location)
// }, 100);
    },[title])
  
    const getProfile = async(e) => {
        
        const res = await axios({
            method: 'GET',
            url: constant.GETPROFILE,
            headers: {
              Authorization: localStorage.getItem('user_token'),
            },
          }).then((response) => {
            checkAuth(response);
            setShimmerEffect('');
            if(response.data.status === true){
                setFirstName(response.data.data.first_name);
                setLastName(response.data.data.last_name);
                setDealershipName(response.data.data.dealership_name);
                setDealerLocation(response.data.data.dealership_location);
                setLongitude(response.data.data.longitude);
                setLatitude(response.data.data.latitude);
                setProvince(response.data.data.province);
                setTimeout(() => {    
                        $(".react-mapbox-ac-input").val(response.data.data.dealership_location)
                }, 100);
            } else {
                
            }
        }).catch(err => {
            checkAuth(err);
            setShimmerEffect('');
            console.log(err);
        });
    }
    
    const getProvinceNew = async () => {
      
        axios({
            method: "GET",
            url: constant.GETPROVINCENEW,
        })
            .then((response) => {
                if (response.data.status == true) {
                    
                    setProvinces(response.data.data);
                }
            })
            .catch((error) => {
               
                console.log("error call ", error);
            });
    };
  

    function onDealerInfoHandler(e) {
        e.preventDefault();         
        let error = 0;
        setErrFirstName("");
        setErrLastName("");
        setErrDealerName("");
        setErrDealerLocation("");
        setErrProvince("");
         
        
         if(first_name===""){
             error++;
             setErrFirstName("This field is required");
             
         }
         if(last_name===""){
             error++;
             setErrLastName("This field is required");
             
         }
       
         
         if(dealership_name===""){
            error++;
            setErrDealerName("This field is required");
            
        }
        
        if(dealership_location===""){
            error++;
            setErrDealerLocation("This field is required");                    
        }
         
        if(province===""){
            error++;
            setErrProvince("This field is required");                    
        }
         
        
        if(error === 0){
             // return <Navigate  to="/step/account_balance" />;
            const USERTOKEN =  localStorage.getItem('user_token');
            setIsLoading(true);
            axios({
                 method: 'POST',
                 url: constant.UPDATEPROFILE,
                 params: {
                    first_name: first_name,
                    last_name: last_name,
                    dealership_name: dealership_name,
                    dealership_location: dealership_location,  
                    latitude: latitude,  
                    longitude: longitude,  
                    province: province,  
                 },
                 headers: {
                   Authorization: USERTOKEN,
                 },
               })
                .then((response) => {
                    setIsLoading(false);
                    if(response.data.status === true){  
                       
                        getProfile();

                        var header_data = {
                            name: response.data.name,
                            matched: response.data.matched,
                            fulfilled: response.data.fulfilled,
                            wallet: response.data.wallet,
                        };
    
                        localStorage.setItem(
                            "header_data",
                            JSON.stringify(header_data)
                        ); 
                         
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                     }
    
                 })
                 .catch((error) => {   
                    setIsLoading(false);
                    console.log('error call ', error);                  
                 });
       
         }     
     
     }
     const handleProvinceChange = (e) => {
        setProvince(e.target.value); // Update state

        setTimeout(() => {    
            $(".react-mapbox-ac-input").val(dealership_location)
    }, 100);
    };

    
        return (
            <>
                <Header />
                <div className="contentCoverbox setfooter_addclass1">
                    <LeftSiderbar />
                    <div
                        className="rightsidecontent"
                        
                    >
                        <SubHeader />
                        <div className="myaccount_mandiv">
                            <div className="create_detailsbox">
                                <div className="create_innerboxdiv">
                                <h2 className="borderBottumSet">Dealership Info</h2>
                                        {/* <div className="account_inputbox">
                                        <p>Email Address* </p>
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Enter Email Address"
                                            onChange={this.onChangehandler}
                                        />
                                        <span className="text-danger">
                                            {this.state.errMsgEmail}
                                        </span>
                                    </div> */}

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="account_inputbox">
                                                <p className={shimmerEffect}>First Name* </p>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    placeholder="Enter First Name"
                                                    className={shimmerEffect}
                                                    onChange={(e) => {
                                                        setFirstName(
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={first_name}
                                                />

                                                <span className="text-danger">
                                                    {err_first_name}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="account_inputbox">
                                                <p className={shimmerEffect}>Last Name*</p>
                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    placeholder="Enter Last Name"
                                                    className={shimmerEffect}
                                                    onChange={(e) => {
                                                        setLastName(
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={last_name}
                                                />

                                                <span className="text-danger">
                                                    {err_last_name}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="account_inputbox">
                                        <p className={shimmerEffect}>Dealership Name*</p>
                                        <input
                                            type="text"
                                            name="dealership_name"
                                            placeholder="Enter Dealership Name"
                                            className={shimmerEffect}
                                            value={dealership_name}
                                            onChange={(e) => {
                                                setDealershipName(
                                                    e.target.value
                                                );
                                            }}
                                        />

                                        <span className="text-danger">
                                            {err_dealership_name}
                                        </span>
                                    </div>
                                    <div className="account_inputbox">
                                        <p className={shimmerEffect}>Dealership Location*</p>
                                        { 
                                        <Paper
                                            component="form"
                                            sx={{
                                                padding: "2px 4px",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            }}>
                                            
                                            <MapboxAutocomplete
                                                publicKey={
                                                    mapAccess.mapboxApiAccessToken
                                                }
                                                inputClass={shimmerEffect}
                                                onSuggestionSelect={
                                                    _suggestionSelect
                                                }
                                                country="ca"
                                                resetSearch={false}
                                                placeholder="Enter Dealership Location"
                                            />
                                        </Paper> 
                                        }

                                        <span className="text-danger">
                                            {err_dealership_location}
                                        </span>
                                    </div>
                                    <div className="account_inputbox">
                                        <p className={shimmerEffect}>Primary Province of Service*</p>
                                        {/* <input
                                            type="text"
                                            name="province"
                                            placeholder="Enter Primary Province"
                                            className={shimmerEffect}
                                            value={province}
                                            onChange={(e) => {
                                                setProvince(
                                                    e.target.value
                                                );
                                            }}
                                        /> */}

<div className="input-group select_province_tag {shimmerEffect}">
                            <select value={province} className="form-control border-end-0 border {shimmerEffect}"
                            onChange={handleProvinceChange} >
                                <option>Select Province</option>
                                {provinces.map((item, index) => {
                                   return <option>{item}</option>;
                                })}
                            </select>
                        </div>

                                        <span className="text-danger">
                                            {err_dealership_name}
                                        </span>
                                    </div>

                                    

                                    <div className="createaccount_btn">
                                        <a
                                            href={void 0}
                                            onClick={(e) =>
                                                onDealerInfoHandler(e)
                                            }
                                        >
                                            {" "}
                                            Update
                                            {isLoading === true ? (
                                                <Loader />
                                            ) : null}
                                        </a>
                                    </div>
                                </div>

                                <ToastContainer />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );    
}

export default Dealerinfo;