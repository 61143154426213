import React,{ Component } from "react";


class login extends Component{
    render() {
        return (
            <>
                <div className="footer_logodiv setfooter_border">
                   <img className="logfirst" src={ require('../../assets/svg/footlogo.svg').default } />                                                          
                  
                </div>
                

            </>

        )
    }
}

export default login;